import React from 'react'
import s from './Divider.module.scss'

const Divider = ({style}) => {
    return (
        <div style={style} className={s.divider} />
    )
}

export default Divider
