@import '../../typography';

.button {
    user-select: none;
    cursor: pointer;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 15px;
    background: $blue;
    border-radius: 8px;
    padding: 13px 16px;
    @include text();
    transition: all 0.4s ease;

    &:disabled {
        cursor: default;
        opacity: 0.5;
    }
    &:hover {
        background: $blue-active;
    }
}
