import React from 'react'
import s from './Divider.module.scss'

const Divider = () => {
    return (
        <div className={s.divider} />
    )
}

export default Divider
