@import './src/_typography.scss';

.services {
    position: relative;
    margin-bottom: 170px;
}

.content {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    background: $dark-background;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    padding: 50px 60px;
}

.title {
    @include h2();
    color: white;
    margin-bottom: 40px;
}

.cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px 30px;
}

@media (max-width: 1200px) {
    .services{
        padding: 0 20px;
        margin-bottom: 100px;
    }
}
@media (max-width: 992px) {
    .content {
        padding: 40px;
    }
    .cards{
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 650px) {
    .title {
        margin-bottom: 25px;
    }
    .content {
        padding: 30px;
    }
    .cards{
        grid-gap: 20px;
        grid-template-columns: 1fr;
    }
}

