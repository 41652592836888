import React from 'react'
import s from './BackgroundImage.module.scss'
import background from '../../assets/images/About_background.svg'
import cl from 'classnames'

const BackgroundImage = ({className = ''}) => {
    return (
        <div className={cl(s.background, className)}>
            <img src={background} alt='background' />
        </div>
    )
}

export default BackgroundImage
