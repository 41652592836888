@import './src/_typography.scss';

.team {
    position: relative;
    margin-bottom: 190px;
}

.content {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
}

.title {
    font-family: 'Blazma', sans-serif;
    @include h2();
    color: $white;
    margin-bottom: 40px;
    text-align: center;
}

.cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

@media (max-width: 1200px) {
    .team {
        padding: 0 20px;
        margin-bottom: 100px;
    }
}

@media (max-width: 480px) {
    .cards {
        grid-template-columns: 1fr;
    }
}
