import React, {forwardRef, useMemo} from 'react'
import {getAllEvents} from '../../utils/getAllEvents'
import {useButton} from './useButton'

export const Button=forwardRef((props, ref) => {
    const {classes} = useButton(props);

    const events=useMemo(() => {
        return getAllEvents(props)
    },[props]);

    return (
        <button type={props.type} {...events} ref={ref} data-testid={props.dataTestId} style={props.style} className={classes} disabled={Boolean(props.disabled)}>
            {props.children}
        </button>
    )
})

