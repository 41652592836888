import React from 'react'
import s from './TeamCard.module.scss'

const TeamCard = ({photo, pattern, name, position}) => {
    return (
        <div className={s.card}>
            <figure className={s.image}>
                <img className={s.pattern} src={pattern} alt='pattern' loading={'lazy'} />
                <img className={s.photo} src={photo} alt={name} loading={'lazy'} />
            </figure>
            <div className={s.info}>
                <div className={s.name}>
                    {name}
                </div>
                <div className={s.position}>
                    {position}
                </div>
            </div>
        </div>
    )
}

export default TeamCard
