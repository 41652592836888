@import './src/_typography.scss';

.input-wrapper {
    position: relative;

    & .label {
        display: block;
        @include p();
        color: $blue;
        margin-bottom: 10px;
        font-family: Gymkhana, sans-serif;
    }

    & .input {
        font-family: Gymkhana, sans-serif;
        white-space: pre-wrap;
        @include p();
        color: $dark;
        width: 100%;
        display: block;
        background-color: transparent;
        border-radius: 6px;
        border: 1px solid $blue;
        padding: 12px 16px;

        &::placeholder {
            color: #0C1133;
            opacity: 0.4;
        }
    }
}

.error {
    position: absolute;
    color: red;
    font-family: Gymkhana, sans-serif;
    font-size: 12px;
    line-height: 14px;
    bottom: -16px;
    width: 100%;
}

.input-wrapper-invalid {
    & .input {
        border: 1px solid red;
    }
}
