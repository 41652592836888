import React, {useState} from 'react'
import s from './ExperienceCard.module.scss'
import frame from '../../../assets/images/Experience/card_frame.svg'
import ExperienceModal from './ExperienceModal/ExperienceModal'
import {isMore1200, isMore768} from '../../../utils/utils'
import Modal from '../../BottomModal/Modal'

const ExperienceCard = ({img, alt, title, tags, data}) => {
    const [showText, setShowText] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const handleClick = () => {
        setIsOpen(true)
    }

    return (
        <>
            <div onClick={handleClick} className={s.card} onMouseEnter={() => setShowText(true)} onMouseLeave={() => setShowText(false)}>
                <div className={s.front} />
                <img className={s.img} src={img} alt={alt} loading={'lazy'} />
                <img className={s.frame} src={frame} alt='frame' loading={'lazy'} />
                <h3 className={s.title}>
                    {title}
                </h3>
                {
                    showText &&
                    <ul className={s.text}>
                    {
                        tags.map((text) => {
                        return (
                            <li key={text}>{text}</li>
                        )})
                    }
                    </ul>
                }
            </div>
            <Modal show={isOpen} style={{width: isMore1200 ? 1200 : window.innerWidth - 40}} onCancel={() => setIsOpen(false)}>
                <ExperienceModal data={data} />
            </Modal>
        </>
    )
}

export default ExperienceCard
