import React from 'react'
import Header from '../components/Header/Header'
import About from '../components/About/About'
import Services from '../components/Services/Services'
import Experience from '../components/Experience/Experience'
import Team from '../components/Team/Team'
import Request from '../components/Request/Request'
import Contacts from '../components/Contacts/Contacts'

const Main = () => {
    return (
        <>
            <Header />
            <About />
            <Services />
            <Experience />
            <Team />
            <Request />
            <Contacts />
        </>
    )
}

export default Main
