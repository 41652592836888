import React, {useContext} from 'react'
import './App.scss'
import 'swiper/swiper.scss'
import './styles/swiper.scss'
import 'react-toastify/dist/ReactToastify.css';
import Main from './pages/Main'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'

const App = () => {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <div className='outer'>
                <Routes>
                    <Route path={''} element={<Main />} />
                </Routes>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </BrowserRouter>
    )
}

export default App
