import React from 'react'
import ReactDOM from 'react-dom';
import s from './Modal.module.scss'
import {useModal} from './useModal'
import * as cl from 'classnames'
import cross from '../../assets/icons/cross.svg'

const Modal = React.memo(({onCancel, onConfirm, show, children, className, style}) => {
    const {modalRef, container, height} = useModal({onCancel, onConfirm, show})

    return ReactDOM.createPortal(
        <>
            {show &&
                <div style={{height: height}} className={s.modalWrapper}>
                    <div className={s.modalShadow} />
                    <div ref={modalRef} style={style} className={cl(s.modal, className)}>
                        <div className={s.inner}>
                            <img onClick={onCancel} src={cross} className={s.cross} alt='cross' />
                            {children}
                        </div>
                    </div>
                </div>
            }
        </>,
        container
    )
})

export default Modal
