import React from 'react'
import s from './Team.module.scss'
import BackgroundImage from '../BackgroundImage/BackgroundImage'
import ceo from '../../assets/images/Ceo.svg'
import creative from '../../assets/images/Creative.svg'
import pattern1 from '../../assets/images/pattern1.svg'
import pattern2 from '../../assets/images/pattern2.svg'
import TeamCard from './TeamCard/TeamCard'

const Team = () => {
    return (
        <section className={s.team} id={'team'}>
            <BackgroundImage />
            <div className={s.content}>
                <h2 className={s.title}>
                    Наша команда
                </h2>
                <div className={s.cards}>
                    <TeamCard name={'Меньшенин Алексей Алексеевич'} position={'CEO'} pattern={pattern1} photo={ceo} />
                    <TeamCard name={'Зубакин Эдуард Андреевич'} position={'CREATIVE'} pattern={pattern2} photo={creative} />
                </div>
            </div>
        </section>
    )
}

export default Team
