import React from 'react'
import s from './Textarea.module.scss'
import {useTextarea} from './useTextarea'

export const Textarea = (props) => {
    const {classes, handleClick} = useTextarea(props)

    return (
        <label htmlFor={props.id ?? props.name} className={classes}>
            {props.label && <label className={s.label}>{props.label}{props.required && <>*</>}</label>}

            <textarea
                   className={s.input}
                   readOnly={Boolean(props.readonly)}
                   autoFocus={Boolean(props.autoFocus)}
                   disabled={Boolean(props.disabled)}
                   data-testid={props.name}
                   placeholder={props.placeholder}
                   name={props.name}
                   id={props.id ?? props.name}
                   required={props.isRequired}
                   maxLength={props.maxLength}
                   tabIndex={props.tabIndex}
                   onClick={handleClick}
                   onFocus={props.onFocus}
                   onKeyUp={props.onKeyUp}
                   {...props.register(props.name)}
            />
            {(props.error && props.errorText) &&
                <div className={s.error}>{props.errorText ?? 'Ошибка'}</div>
            }
        </label>
    )
}
