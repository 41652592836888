import React from 'react'
import s from './About.module.scss'
import {Button} from '../Button/Button'
import football from '../../assets/images/Football.svg'
import BackgroundImage from '../BackgroundImage/BackgroundImage'
import {HashLink} from 'react-router-hash-link'

const About = () => {
    return (
        <section id={'about'} className={s.container}>
            <BackgroundImage className={s.background} />
            <div className={s.content}>
                <div className={s.info}>
                    <div>
                        <h1 className={s.title}>
                            Агенство спортивного менеджмента
                        </h1>
                        <div className={s.subtitle}>
                            Поможем создать вашу уникальную историю или возьмем на себя ряд ваших заурядных задач
                        </div>
                        <HashLink to="/#services" scroll={(el) => scrollTo({top: el.offsetTop - 150, behavior: 'smooth'})}><Button>Подробнее</Button></HashLink>
                    </div>
                </div>
                <div className={s.image}>
                    <img src={football} alt='football' />
                </div>
            </div>
        </section>
    )
}

export default About
