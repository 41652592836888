@import './src/_typography.scss';

.card {
    border-radius: 4px;
    background-color: $dark-background;
    position: relative;
}

.image {
    border-radius: 4px 4px 0 0;
    position: relative;
    padding: 15px 0 0;
    background-color: $blue;
}

.pattern {
    display: block;
    @include absoluteCenter();
    top: 14px;
    z-index: 1;
    width: 100%;
}

.photo {
    position: relative;
    z-index: 2;
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 350px;
}

.info {
    position: relative;
    z-index: 2;
    padding: 15px;
}

.name {
    text-align: center;
    @include h3();
    color: $white;
    font-style: italic;
    font-family: Blazma, sans-serif;
}

.position {
    text-align: center;
    padding: 0 15px;
    @include p();
    color: rgba(255,255,255,0.7)
}

