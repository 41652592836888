import React, {useState} from 'react'
import s from './ExperienceModal.module.scss'
import Divider from './Divider/Divider'
import "swiper/modules/free-mode/free-mode.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/thumbs/thumbs.min.css";
import {FreeMode, Navigation, Thumbs} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'
import cl from 'classnames'

const ExperienceModal = ({data}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const {images, title, decision, location, date, customer} = data
    return (
        <div className={s.modal}>
            <div className={s.info}>
                <h2 className={s.title}>
                    {title}
                </h2>
                <div className={s.date}>
                    {date}
                </div>
                <div className={s.location}>
                    <div className={s.name}>
                        Локация
                    </div>
                    <div className={s.value}>
                        {location}
                    </div>
                </div>
                <Divider />
                <div className={s.location}>
                    <div className={s.name}>
                        Заказчик
                    </div>
                    <div className={s.value}>
                        {customer}
                    </div>
                </div>
                <Divider />
                <div className={s.location}>
                    <div className={s.name}>
                        Решение
                    </div>
                    <div className={s.value}>
                        {decision}
                    </div>
                </div>
            </div>
            <div className={s.slider}>
                <Swiper
                    loop={true}
                    spaceBetween={10}
                    navigation={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper2"
                >
                    {
                        images.map((item, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <img className={cl(s.img, s.mainImg)} loading={'lazy'} src={item} alt='item' />
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
                <div className={s.wrapper}>
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        loop={true}
                        spaceBetween={10}
                        slidesPerView={images.length >= 3 ? 3 : 2}
                        freeMode={true}
                        watchSlidesProgress={true}
                        navigation={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper"
                    >
                        {
                            images.map((item, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <img className={s.img} loading={'lazy'} src={item} alt='item' />
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default ExperienceModal
