@import './src/_typography.scss';

.item {
}

.name {
    @include text();
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 5px;
    text-align: center;
}
