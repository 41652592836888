import React from 'react'
import s from './StatItem.module.scss'

const StatItem = ({children, text}) => {
    return (
        <div className={s.item}>
            {children}
            <div className={s.text}>
                {text}
            </div>
        </div>
    )
}

export default StatItem
