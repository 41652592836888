import torpedo1 from '../assets/images/Experience/Swiper/Torpedo/torpedo1.svg'
import torpedo2 from '../assets/images/Experience/Swiper/Torpedo/torpedo2.svg'
import football1 from '../assets/images/Experience/Swiper/Football/football1.svg'
import football2 from '../assets/images/Experience/Swiper/Football/football2.svg'
import football3 from '../assets/images/Experience/Swiper/Football/football3.svg'
import football4 from '../assets/images/Experience/Swiper/Football/football4.svg'
import winliner1 from '../assets/images/Experience/Swiper/Winliner/winliner1.svg'
import winliner2 from '../assets/images/Experience/Swiper/Winliner/winliner2.svg'
import winliner3 from '../assets/images/Experience/Swiper/Winliner/winliner3.svg'
import winliner4 from '../assets/images/Experience/Swiper/Winliner/winliner4.svg'
import winliner5 from '../assets/images/Experience/Swiper/Winliner/winliner5.svg'
import winliner6 from '../assets/images/Experience/Swiper/Winliner/winliner6.svg'
import winliner7 from '../assets/images/Experience/Swiper/Winliner/winliner7.svg'
import winliner8 from '../assets/images/Experience/Swiper/Winliner/winliner8.svg'
import superleague1 from '../assets/images/Experience/Swiper/SuperLeague/superleague1.svg'
import superleague2 from '../assets/images/Experience/Swiper/SuperLeague/superleague2.svg'
import ghetto1 from '../assets/images/Experience/Swiper/GhettoBasketball/ghetto1.svg'
import ghetto2 from '../assets/images/Experience/Swiper/GhettoBasketball/ghetto2.svg'
import work1 from '../assets/images/Experience/Swiper/Work/work1.svg'
import work2 from '../assets/images/Experience/Swiper/Work/work2.svg'
import work3 from '../assets/images/Experience/Swiper/Work/work3.svg'

export const data = {
    torpedo: {
        title: 'Организация домашних матчей Футбольного клуба Торпедо',
        date: 'Сезон 2022 — 2023',
        location: 'БСА Лужники',
        customer: 'ФК Торпедо',
        decision: 'Организовали работу персонала на домашних матчах ФК Торпедо:\n' +
            '– Работа клубного магазина\n' +
            '– Организация Акций на трибуне\n' +
            '– Вынос центрального круга\n' +
            '– Выдача аккредитаций в зоне СМИ',
        images: [torpedo1, torpedo2]
    },
    football: {
        title: 'Организация VK Football CUP 3x3',
        date: '22 июля 2022',
        location: 'Сириус',
        customer: 'VK',
        decision: 'Придумали концепцию и провели под ключ футбольный турнир во время VKFest в городе Сочи.',
        images: [football1, football2, football3, football4]
    },
    winliner: {
        title: 'Организация Winliner',
        date: '5 — 12 июня 2022',
        location: 'река Москва',
        customer: 'Winline x ФК Спартак',
        decision: 'Организовали работу всей спортивной части Winliner\'а:\n' +
            '\n' +
            '– Предоставили и скоординировали работу спортивного персонала\n' +
            '\n' +
            '– Провели регистрацию и координацию команд, участников активностей и турнира\n' +
            '\n' +
            '– Провели непрерывный матч протяженностью 1922 минуты',
        images: [winliner1, winliner2, winliner3, winliner4, winliner5, winliner6, winliner7, winliner8]
    },
    superleague: {
        title: 'Организация  League-F',
        date: 'Сезон 2022',
        location: 'Москва, стадион "Вернадка-парк"',
        customer: 'League-F',
        decision: 'Подобрали и скоординировали работу спортивного персонала на турнире, вели социальные сети в дни матчей',
        images: [superleague1, superleague2]
    },
    ghetto: {
        title: 'Организация GHETTO BASKET',
        date: '11, 17, 24 сентября 2022',
        location: 'Москва, ПодМостом',
        customer: 'Ассоциация уличного баскетбола',
        decision: 'Подобрали и скоординировали работу спортивного персонала на турнире',
        images: [ghetto1, ghetto2]
    },
    work: {
        title: 'ОРГАНИЗАЦИЯ МероприятиЙ ВФСО "Трудовые резервы"',
        date: 'Сезон 2021, 2022',
        location: 'Москва, Сочи, Уфа и другие города.',
        customer: 'ВФСО "Трудовые резервы"',
        decision: 'Предоставляем вспомогательный персонал во время проведения мероприятий',
        images: [work1, work2, work3]
    },
}
