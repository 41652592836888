import React from 'react'
import s from './ExperienceStat.module.scss'
import StatItem from './StatItem/StatItem'
import infinity from '../../../assets/icons/Infinity.svg'
import Divider from './StatItem/Divider/Divider'
import {isMore650} from '../../../utils/utils'

const ExperienceStat = () => {
    return (
        <div className={s.stat}>
            <StatItem text={'Проведенных \nмероприятий'}>
                <div className={s.itemTitle}>
                    100+
                </div>
            </StatItem>

            <Divider />

            <StatItem text={'Довольных \nклиентов'}>
                <div className={s.itemTitle}>
                    20+
                </div>
            </StatItem>

            {
                isMore650 && <Divider />
            }


            <StatItem text={'Человек \nв команде'}>
                <div className={s.itemTitle}>
                    <img src={infinity} alt='infinity' />
                </div>
            </StatItem>

            <Divider />

            <StatItem text={'Лет проводим \nмероприятия'}>
                <div className={s.itemTitle}>
                    5+
                </div>
            </StatItem>
        </div>
    )
}

export default ExperienceStat
