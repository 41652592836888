import React from 'react'
import s from './ContactItem.module.scss'
import cl from 'classnames'

const ContactItem = ({className, name, children}) => {
    return (
        <div className={cl(s.item)}>
            <div className={cl(s.name, className)}>
                {name}
            </div>
            {children}
        </div>
    )
}

export default ContactItem
