import React, {useState} from 'react'
import s from './Request.module.scss'
import BackgroundImage from '../BackgroundImage/BackgroundImage'
import Divider from './Divider/Divider'
import RequestStep from './RequestStep/RequestStep'
import {Input} from '../Input/Input'
import * as Yup from 'yup'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup/dist/yup'
import {notifyError, notifySuccess, phoneRegex} from '../../utils/utils'
import RequestFooter from './RequestFooter/RequestFooter'
import {instance} from '../../api/api'

const Request = () => {
    const [isLoading, setIsLoading] = useState(false)
    const schema = Yup.object().shape({
        name: Yup.string().required('Заполните ФИО'),
        phone: Yup.string().required('Заполните номер телефона').matches(phoneRegex, 'Введите корректный номер телефона'),
        telegram: Yup.string().required('Заполните ник телеграма'),
    });

    const {register, formState: {errors}, handleSubmit, reset} = useForm({ resolver: yupResolver(schema)})

    const onSubmit = async (data) => {
        try {
            setIsLoading(true)
            const body = {
                full_name: data.name,
                phone_number: data.phone,
                tg_username: data.telegram,
                description: data.about,
            }
            await instance.post('api/customer', body)
                .then(response => {
                    if(response.data.message) {
                        setIsLoading(false)
                        reset({
                            name: '', phone: '', telegram: ''
                        })
                        notifySuccess('Ваша заявка отправлена')
                    }
                })
        }
        catch (e) {
            notifyError('Что-то пошло не так')
            setIsLoading(false)
        }
    }

    return (
        <section className={s.request}>
            <BackgroundImage />
            <form onSubmit={handleSubmit(onSubmit)} className={s.content}>
                <h2 className={s.title}>
                    Оставьте нам заявку
                </h2>
                <div className={s.info}>
                    <div className={s.steps}>
                        <RequestStep text={'Оставьте заявку на нашем сайте'} number={1} />
                        <Divider />
                        <RequestStep text={'Мы свяжемся с вами в течение дня по поводу заявки'} number={2} />
                        <Divider />
                        <RequestStep text={'Обсудим детали и сформируем дальнейший план действий'} number={3} />
                    </div>
                    <div className={s.form}>
                        <Input error={errors['name']} errorText={errors['name']?.message} label={'ФИО'} placeholder={'Иванов Иван Иванович'} required name={'name'} register={register} />
                        <Input error={errors['phone']} errorText={errors['phone']?.message} type={'tel'} label={'Номер телефона'} placeholder={'+79673647814'} required name={'phone'} register={register}/>
                        <Input error={errors['telegram']} errorText={errors['telegram']?.message}  label={'Telegram'} placeholder={'@username'} required name={'telegram'} register={register}/>
                    </div>
                </div>
                <RequestFooter isLoading={isLoading} />
            </form>
        </section>
    )
}

export default Request
