@import './src/_typography.scss';

.modal {

}

.title {
    @include h2();
    font-family: 'Blazma', sans-serif;
    margin-bottom: 40px;
    color: $dark;
}

.steps {
    margin-bottom: 40px;
}

.inputs {
    margin-bottom: 40px;
}

.input {
    margin-bottom: 30px;
}

@media (max-width: 650px) {
    .logo {

        & img {
            max-width: 200px;
        }
    }
}
