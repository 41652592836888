@import './src/_typography.scss';

.card {
    width: 100%;
    position: relative;
    cursor: pointer;
    border-radius: 8px;
}

.front {
    width: 100%;
    @include absoluteCenter();
    background-color: rgba(12, 17, 51, 0.5);
    border-radius: 8px;
    transition: all 0.2s ease;

    &:hover {
        background-color: rgba(7, 76, 237, 0.95);
    }
}

.img {
    display: block;
    width: 100%;
    border-radius: 8px;
}

.frame {
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}

.title {
    position: absolute;
    top: 30px;
    left: 30px;
    right: 30px;
    pointer-events: none;

    @include h3();
    font-style: italic;
    color: $white;
    font-family: Blazma, sans-serif;
}

.text {
    pointer-events: none;
    position: absolute;
    left: 45px;
    bottom: 30px;
    @include text();
    color: white;
    animation: text ease 0.2s;

    & li {
        margin-bottom: 10px;
    }
}

@keyframes text {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }

}

@media (max-width: 768px) {
    .title {
        top: 15px;
        left: 15px;
        right: 15px;
    }
}
@media (max-width: 480px) {
    .img {

    }
}
