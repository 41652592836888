import React, {useCallback, useEffect, useMemo, useState} from 'react'

export const useModal = (props) => {
    const modalRef = React.createRef()
    const [height, setHeight] = useState(window.innerHeight);
    const container = useMemo(() => {
        return document.createElement('div');
    }, [])

    useEffect(() => {
        window.addEventListener('mousedown', handleClickOutside)
        return () => {
            window.removeEventListener('mousedown', handleClickOutside)
        }
    }, [modalRef])

    useEffect(() => {
        if(props.show) {
            setTimeout(() => {
                document.body.style.overflow = 'hidden'
            }, 500)
        }
        else {
            document.body.removeAttribute('style')
        }
        return () => {
            document.body.removeAttribute('style')
        }

    }, [props.show])

    const handleClickOutside = useCallback((event) => {
        if(props.show && modalRef.current && !modalRef.current.contains(event.target)) {
            props.onCancel()
        }
    }, [props, modalRef])

    useEffect(() => {
        window.addEventListener('resize', () => setHeight(window.innerHeight))

        return () => {
            window.removeEventListener('resize', () => setHeight(window.innerHeight))
        }
    }, []);

    useEffect(() => {
        document.body.appendChild(container);
        return () => {
            document.body.removeChild(container);
        };
    }, []);

    return {modalRef, container, height}
}
