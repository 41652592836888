@import './src/_typography.scss';

.experience {
    position: relative;
    margin-bottom: 220px;
}

.content {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
}

.title {
    @include h2();
    color: $white;
    margin-bottom: 40px;
    text-align: center;
    font-family: 'Blazma', sans-serif;
}

.events {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px
}

@media (max-width: 1200px) {
    .experience{
        padding: 0 20px;
        margin-bottom: 100px;
    }
    .title {
        margin-bottom: 25px;
    }
}
@media (max-width: 650px) {
    .events{
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 480px) {
    .events{
        grid-template-columns: 1fr;
    }
}
