import React from 'react'
import s from './RequestFooter.module.scss'
import {Button} from '../../Button/Button'
import Preloader from '../../Preloader/Preloader'

const RequestFooter = ({isLoading}) => {
    return (
        <div className={s.footer}>
            <div className={s.agreed}>
                Нажимая кнопку «Отправить», я даю свое согласие на обработку персональных данных
            </div>
            <Button isLoading={isLoading} type={'submit'} className={s.button}>
                Отправить
                {isLoading && <Preloader />}
            </Button>
        </div>
    )
}

export default RequestFooter
