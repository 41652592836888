import React from 'react'
import s from './RequestStep.module.scss'

const RequestStep = ({number, text}) => {
    return (
        <div className={s.step}>
            <div className={s.number}>
                {number}
            </div>
            <div className={s.text}>
                {text}
            </div>
        </div>
    )
}

export default RequestStep
