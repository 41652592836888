@import './src/_typography.scss';

.navigation {
    width: fit-content;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    list-style: none;
}

.link {

    & a {
        transition: color 0.1s ease;
        @include p();
        color: rgba(255,255,255, 0.7);

        &:hover {
            color: rgba(255,255,255, 1);
        }
    }
}

.active {
    & a {
        color: rgba(255,255,255, 1);
    }
}

@media (max-width: 992px) {
    .navigation {
        display: block;
        padding: 20px;
    }
    .link {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .link a {
        color: rgba(0,0,0, 0.7);
        display: block;

        &:hover {
            color: initial;
        }
    }

    .active {
        & a {
            color: $dark-blue;
        }
    }
}
