import {toast} from 'react-toastify'

export const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/

export const getActualYear = () => {
    return new Date().getFullYear()
}

export const isMore1200 = window.innerWidth > 1200
export const isMore992 = window.innerWidth > 992
export const isMore768 = window.innerWidth > 768
export const isMore650 = window.innerWidth > 650
export const isMore480 = window.innerWidth > 480

export const notifySuccess = (text) => toast.info(text)
export const notifyError = (text) => toast.error(text)
