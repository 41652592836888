@import './src/_typography.scss';

.modal {
    display: grid;
    grid-template-columns: 1fr 620px;
    grid-gap: 40px;
}

.info {
    width: 100%;
}

.slider {
    width: 100%;
}


.title {
    @include h2();
    font-family: 'Blazma', sans-serif;
    margin-bottom: 10px;
}

.date {
    color: #0C1133;
    opacity: 0.5;
    @include h4();
    margin-bottom: 30px;
}

.location {
    width: 100%;
}

.name {
    margin-bottom: 5px;
    color: $blue;
    @include h4();
}

.value {
    @include h4();
    color: $dark;
    white-space: pre-wrap;
}

.wrapper {
    margin-top: 10px;
}

.mainImg {
    min-height: 410px;
    border-radius: 4px 4px 10px 10px !important;
}

.img {
    width: 100%;
    display: block;
    user-select: none;
    border-radius: 4px;
}

@media (max-width: 992px) {
    .modal {
        grid-template-columns: 1fr;
    }

    .mainImg {
        min-height: 0;
    }
}
@media (max-width: 650px) {
    .modal {
        grid-gap: 20px;
    }
}

