import React from 'react'
import s from './Services.module.scss'
import ServiceCard from './ServiceCard/ServiceCard'
import BackgroundImage from '../BackgroundImage/BackgroundImage'

const Services = () => {
    return (
        <section className={s.services} id={'services'}>
            <BackgroundImage />
            <div className={s.content}>
                <h2 className={s.title}>
                    Что мы делаем
                </h2>
                <div className={s.cards}>
                    <ServiceCard color={'#88ED07'} title={`Подбор персонала на мероприятие`} number={'01'} text={'Подберем персонал любой сложности, организуем работу и инструктаж'} />
                    <ServiceCard color={'#EDC807'} title={'Проработка логистики'} number={'02'} text={'Проработаем задачи, требующие интеллектуального труда: от набора участников, до навигации'} />
                    <ServiceCard color={'#ED7507'} title={'Брендинг мероприятия'} number={'03'} text={'Разработаем логотип, брендбук и фирменный стиль вашего проекта'} />
                    <ServiceCard color={'#074CED'} title={'Ведение \nсоц. сетей'} number={'04'} text={'Красиво и качественно наши контентмейкеры заполнят ваши социальные страницы'} />
                    <ServiceCard color={'#A307ED'} title={'Проведение мероприятий'} number={'05'} text={'Если вы не хотите заниматься сами, проведем ивент совместно с вами'} />
                    <ServiceCard color={'#ED0783'} title={'Проведение активностей'} number={'06'} text={'Закроем под ключ любую вашу задачу на мероприятии'} />
                </div>
            </div>
        </section>
    )
}

export default Services
