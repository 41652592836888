import React, {useState} from 'react'
import s from './HeaderForm.module.scss'
import * as Yup from 'yup'
import {notifyError, notifySuccess, phoneRegex} from '../../../utils/utils'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup/dist/yup'
import RequestStep from '../../Request/RequestStep/RequestStep'
import Divider from '../../Request/Divider/Divider'
import {Input} from '../../Input/Input'
import RequestFooter from '../../Request/RequestFooter/RequestFooter'
import {Textarea} from '../../TextArea/Textarea'
import {instance} from '../../../api/api'

const HeaderForm = ({setIsOpen}) => {
    const [isLoading, setIsLoading] = useState(false)

    const schema = Yup.object().shape({
        name: Yup.string().required('Заполните ФИО'),
        phone: Yup.string().required('Заполните номер телефона').matches(phoneRegex, 'Введите корректный номер телефона'),
        telegram: Yup.string().required('Заполните ник телеграма'),
        about: Yup.string().required('Расскажите о себе'),
    })

    const {
        register,
        formState: {errors},
        handleSubmit,
        reset,
    } = useForm({resolver: yupResolver(schema)})

    const onSubmit = async (data) => {
        try {
            setIsLoading(true)
            const body = {
                full_name: data.name,
                phone_number: data.phone,
                tg_username: data.telegram,
                description: data.about,
            }
            await instance.post('api/worker', body)
                .then(response => {
                    if(response.data.message) {
                        setIsLoading(false)
                        notifySuccess('Ваша заявка отправлена')
                        reset({
                            name: '', phone: '', telegram: '', about: ''
                        })
                        setIsOpen(false)
                    }
                })
        }
        catch (e) {
            notifyError('Что-то пошло не так')
            setIsLoading(false)
        }
    }

    return (
        <div className={s.modal}>
            <h2 className={s.title}>Присоединиться к команде</h2>
            <div className={s.steps}>
                <RequestStep text={'Оставьте заявку на нашем сайте'} number={1} />
                <Divider style={{height: 40}} />
                <RequestStep text={'Мы свяжемся с вами в течение дня по поводу заявки'} number={2} />
                <Divider style={{height: 40}} />
                <RequestStep text={'Обсудим вашу роль в команде'} number={3} />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={s.inputs}>
                    <div className={s.input}>
                        <Input error={errors['name']} errorText={errors['name']?.message} label={'ФИО'}
                               placeholder={'Иванов Иван Иванович'} required name={'name'} register={register} />
                    </div>
                    <div className={s.input}>
                        <Input error={errors['phone']} errorText={errors['phone']?.message} type={'tel'}
                               label={'Номер телефона'} placeholder={'+79673647814'} required name={'phone'}
                               register={register} />
                    </div>
                    <div className={s.input}>
                        <Input error={errors['telegram']} errorText={errors['telegram']?.message} label={'Telegram'}
                               placeholder={'@username'} required name={'telegram'} register={register} />
                    </div>
                    <div className={s.input}>
                        <Textarea error={errors['about']} errorText={errors['about']?.message}
                               label={'Чем ты можешь быть нам полезен'}
                               placeholder={'Я супер талантливый и ответственный работник'} required name={'about'}
                               register={register} />
                    </div>
                </div>
                <RequestFooter isLoading={isLoading} />
            </form>
        </div>
    )
}

export default HeaderForm
