import React from 'react'
import s from './Experience.module.scss'
import {data} from '../../utils/data'
import BackgroundImage from '../BackgroundImage/BackgroundImage'
import ExperienceStat from './ExperienceStat/ExperienceStat'
import ExperienceCard from './ExperienceCard/ExperienceCard'
import torpedo from '../../assets/images/Experience/torpedo.png'
import football from '../../assets/images/Experience/football_cup.png'
import ghetto from '../../assets/images/Experience/ghetto.png'
import superLeague from '../../assets/images/Experience/superleague.png'
import winliner from '../../assets/images/Experience/winliner.png'
import work from '../../assets/images/Experience/work.png'

const Experience = () => {
    return (
        <section className={s.experience} id={'experience'}>
            <BackgroundImage />
            <div className={s.content}>
                <h2 className={s.title}>
                    Наш опыт
                </h2>
                <ExperienceStat />

                <div className={s.events}>
                    <ExperienceCard img={torpedo} alt={'ФК "Торпедо"'} title={'Организация домашних матчей ФК "Торпедо"'} tags={['Сезон 2022 — 2023', 'БСА Лужники', 'ФК Торпедо']} data={data.torpedo} />
                    <ExperienceCard img={football} alt={'Football CUP 3x3'} title={'ОРГАНИЗАЦИЯ VK Football CUP 3x3'} tags={['22 июля 2022', 'Сириус', 'VK']} data={data.football} />
                    <ExperienceCard img={winliner} alt={'Winliner'} title={'Организация Winliner'} tags={['5 — 12 июня 2022', 'Река Москва', 'Winline x ФК Спартак']} data={data.winliner} />
                    <ExperienceCard img={superLeague} alt={'League-F'} title={'Организация League-F'} tags={['Сезон 2022', 'стадион «Вернадка-парк»', 'League-F']} data={data.superleague} />
                    <ExperienceCard img={ghetto} alt={'GHETTO BASKET'} title={'ОРГАНИЗАЦИЯ GHETTO BASKET'} tags={['11, 17, 24 сентября 2022', 'Москва, ПодМостом', 'Ассоциация уличного баскетбола']} data={data.ghetto} />
                    <ExperienceCard img={work} alt={'ВФСО "Трудовые резервы"'} title={'ОРГАНИЗАЦИЯ МероприятиЙ ВФСО "Трудовые резервы"'} tags={['Сезон 2021 — 2022', 'Москва, Сочи, Уфа', 'ВФСО «Трудовые резервы»']} data={data.work} />
                </div>
            </div>
        </section>
    )
}

export default Experience
