import React from 'react'
import s from './ServiceCard.module.scss'
import Top from 'jsx:../../../assets/images/Service_Card_top.svg'

const ServiceCard = ({color, title, text, number}) => {
    return (
        <div className={s.container}>
            <div className={s.img}>
                <Top fill={color} />
            </div>
            <h3 className={s.title}>
                <div style={{color: color}}>
                    {number}
                </div>
                <div>
                    {title}
                </div>
            </h3>
            <p className={s.text}>
                {text}
            </p>
        </div>
    )
}

export default ServiceCard
