@import './src/_typography.scss';

.container{
    position: relative;
    height: calc(100vh - 104px);
}

.background{
    top: 0;
    transform: translateY(-35px);
}

.content {
    width: 100%;
    transform: translateY(-52px);
    max-width: 1200px;
    margin: 0 auto;
    @include absoluteCenter();
    z-index: 2;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-column-gap: 30px;
}

.info{
    display: grid;
    align-items: center;
}

.title{
    @include h1();
    color: $blue;
    margin-bottom: 10px;
}

.subtitle{
    width: fit-content;
    color: $white;
    @include h4();
    margin-bottom: 30px;
}

.image{
    user-select: none;
    display: grid;
    align-items: center;

    & img {
        display: block;
    }
}

@media (max-width: 1200px) {
    .background{
        transform: translateY(0);
    }
    .content {
        padding: 0 20px;
        transform: translateY(0);
    }

    .image img {
        max-width: 50vw;
    }
}

@media (max-width: 650px) {
    .image {
        display: none;
    }
}
@media (max-width: 480px) {
    .container {
        height: calc(100vh - 97px);
    }
    .content {
       grid-template-columns: 1fr;
    }
}

