import s from './Textarea.module.scss'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {getClasses} from '../../utils/getClasses'

export function useTextarea(props) {
    const handleClick = useCallback((e) => {
        if(props.onClick) props.onClick(e)
    }, [props.onClick])

    const classes = useMemo(() => {
        const conditions = {
            "input-wrapper": true,
            "input-wrapper-disabled": Boolean(props.disabled),
            "input-wrapper-invalid":  Boolean(props.error),
        };
        return getClasses(conditions, s, props.className)
    }, [props]);

    return {classes, handleClick}
}
