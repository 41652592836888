@import './src/_typography.scss';

.request {
    position: relative;
    margin-bottom: 170px;
}

.content {
    max-width: 850px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    background-color: $white;
    border-radius: 15px;
    padding: 50px 60px;
}

.title {
    font-family: 'Blazma', sans-serif;
    @include h2();
    margin-bottom: 40px;
    color: $dark;
}

.info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    margin-bottom: 40px;
}

.steps {

}

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 15px;
}

@media (max-width: 1200px) {
    .request {
        padding: 0 20px;
        margin-bottom: 100px;
    }
}
@media (max-width: 650px) {
    .content {
        padding: 20px 30px;

    }
}
@media (max-width: 480px) {
    .info {
        grid-template-columns: 1fr;
        grid-gap: 20px;
        margin-bottom: 25px;
    }
}
