import React, {useEffect, useState} from 'react'
import cl from 'classnames'
import s from './HeaderLinks.module.scss'
import {HashLink} from 'react-router-hash-link'
import {useLocation} from 'react-router-dom'

const HeaderLinks = ({dispatchOpen}) => {
    const location = useLocation()
    const [activeHash, setActiveHash] = useState('')

    useEffect(() => {
        if(location.hash !== '') {
            setActiveHash(location.hash)
        }
    }, [location])

    return (
        <nav>
            <ul className={s.navigation}>
                <li onClick={dispatchOpen} className={cl(s.link, activeHash === '#about' && s.active)}>
                    <HashLink to="/#about" scroll={(el) => scrollTo({top: el.offsetTop - 150, behavior: 'smooth'})}>О нас</HashLink>
                </li>
                <li onClick={dispatchOpen} className={cl(s.link, activeHash === '#services' && s.active)}>
                    <HashLink to="/#services" scroll={(el) => scrollTo({top: el.offsetTop - 150, behavior: 'smooth'})}>Услуги</HashLink>
                </li>
                <li onClick={dispatchOpen} className={cl(s.link, activeHash === '#experience' && s.active)}>
                    <HashLink to="/#experience" scroll={(el) => scrollTo({top: el.offsetTop - 150, behavior: 'smooth'})}>Опыт</HashLink>
                </li>
                <li onClick={dispatchOpen} className={cl(s.link, activeHash === '#team' && s.active)}>
                    <HashLink to="/#team" scroll={(el) => scrollTo({top: el.offsetTop - 150, behavior: 'smooth'})}>Команда</HashLink>
                </li>
                <li onClick={dispatchOpen} className={cl(s.link, activeHash === '#contacts' && s.active)}>
                    <HashLink to="/#contacts" scroll={(el) => scrollTo({top: el.offsetTop - 150, behavior: 'smooth'})}>Контакты</HashLink>
                </li>
            </ul>
        </nav>
    )
}

export default HeaderLinks
