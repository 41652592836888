import React, {useState} from 'react'
import s from './ContactsJoin.module.scss'
import {Button} from '../../Button/Button'
import HeaderForm from '../../Header/HeaderForm/HeaderForm'
import {isMore768} from '../../../utils/utils'
import Modal from '../../BottomModal/Modal'

const ContactsJoin = () => {
    const [isOpen, setIsOpen] = useState(false)
    const handleClick = () => {
        setIsOpen(true)
    }
    return (
        <div className={s.join}>
            <h2 className={s.title}>Присоединиться к команде</h2>
            <h3 className={s.subtitle}>
                {`Есть желание присоединиться к Alliance Sport Agency?\nПрисылай нам свои контакты!`}
            </h3>
            <Button onClick={handleClick} className={s.button}>Хочу присоединиться</Button>

            <Modal show={isOpen} style={{maxWidth: isMore768 ? 670 : window.innerWidth - 40}} onCancel={() => setIsOpen(false)}>
                <HeaderForm setIsOpen={setIsOpen} />
            </Modal>
        </div>
    )
}

export default ContactsJoin
