@import './src/_typography.scss';

.step {
    display: grid;
    grid-template-columns: 32px 1fr;
    align-items: center;
    grid-gap: 15px;
}

.number {
    color: $white;
    background-color: blue;
    border-radius: 10px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text {
    @include p();
}
