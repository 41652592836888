@import './src/_typography.scss';

.container {
    position: relative;
    background: linear-gradient(282.51deg, rgba(0, 13, 97, 0.02) 0.06%, rgba(255, 255, 255, 0) 100%);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}

.img {
    padding-top: 20px;

    & svg {
        display: block;
        width: 100%;
    }
}

.title {
    @include h3();
    margin-bottom: 20px;
    color: $white;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 15px;
    padding: 20px 20px 0;

    & div {
        white-space: pre-wrap;
    }
}

.text {
    margin: 0;
    @include text();
    color: $white;
    opacity: 0.8;
    padding: 0 20px 20px;
}

@media (max-width: 320px) {
    .title {
        word-break: break-word;
    }
}
