@import './src/_typography.scss';

.divider {
    width: 1px;
    height: 81px;
    border-left: 1px dashed #0C1133;
    opacity: 0.4;
    margin: 0 15px;
}

@media (max-width: 480px) {
    .divider {
        height: 40px;
    }
}

