import React, {useReducer, useState} from 'react'
import s from './Header.module.scss'
import logo from '../../assets/images/logo.svg'
import {Button} from '../Button/Button'
import HeaderForm from './HeaderForm/HeaderForm'
import HeaderLinks from './HeaderLinks/HeaderLinks'
import {SidePane} from 'react-side-pane'
import {isMore480, isMore768, isMore992} from '../../utils/utils'
import burger from '../../assets/icons/burger.svg'
import Modal from '../BottomModal/Modal'

const Header = () => {
    const [isOpen, setIsOpen] = useState(false)

    const handleClick = () => {
        setIsOpen(true)
    }

    const [open, dispatchOpen] = useReducer((prev) => !prev, false);

    return (
        <header className={s.header}>
            <div className={s.container}>
               <div className={s.logo}>
                   <img src={logo} alt='Alliance' />
               </div>

                {
                    isMore992
                        ?
                        <>
                            <HeaderLinks />
                            <Button onClick={handleClick} className={s.button}>Оставить заявку</Button>
                        </>
                        : <Button onClick={dispatchOpen} className={s.button}><img src={burger} alt='open button' /></Button>
                }
            </div>


            <SidePane open={open} width={isMore480 ? 30 : 45} onClose={dispatchOpen}>
                <>
                    <HeaderLinks dispatchOpen={dispatchOpen} />
                    <div className={s.requestButtonContainer}>
                        <Button onClick={() => {
                            handleClick()
                            dispatchOpen()
                        }} className={s.requestButton}>Оставить заявку</Button>
                    </div>
                </>
            </SidePane>

            <Modal style={{maxWidth: isMore768 ? 670 : window.innerWidth - 40}} show={isOpen} onCancel={() => setIsOpen(false)}>
                <HeaderForm setIsOpen={setIsOpen} />
            </Modal>
        </header>
    )
}

export default Header
