@import './src/_typography.scss';

.item {
    padding: 40px;
    width: fit-content;
    margin: 0 auto;
}

.text {
    @include p();
    color: $white;
    text-align: center;
    white-space: pre-wrap;
}

@media (max-width: 1200px) {
    .item {
        padding: 25px;
    }
}
@media (max-width: 650px) {
    .item {
        padding: 20px 10px;
    }
}
