@import './src/_typography.scss';

.container {

}

.contacts {
    max-width: 1200px;
    margin: 0 auto 110px;
    @include flexible();
    justify-content: space-between;
    gap: 50px
}

.footer {
    width: 100%;
    background-color: $dark-background;
    padding: 30px;
    @include p();
    color: $white;
    text-align: center;
}

.value {
    display: block;
    @include text();
    color: $white;
    text-align: center;
    margin: 0 auto;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

@media (max-width: 1200px) {
    .container {
        padding: 0 20px;
    }
    .contacts {
        margin-bottom: 75px;
    }
}

@media (max-width: 768px) {
    .contacts {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
        align-items: flex-start;
    }
    .left, .right {
        text-align: center;
    }
}
@media (max-width: 480px) {
    .contacts {
        grid-template-columns: 1fr;
        margin-bottom: 50px;
    }
}
