@import './src/_typography.scss';

.footer {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 30px;
    align-items: center;
}

.agreed {
    font-size: 12px;
    line-height: 14px;
    color: $dark;
    opacity: 0.5;
    user-select: none;
}

@media (max-width: 480px) {
    .footer {
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }

    .button {
        width: fit-content;
        margin-left: auto;
    }
}
