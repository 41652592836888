@import './src/_typography.scss';

.join {
    max-width: 1200px;
    background-color: $dark-background;
    padding: 50px 30px;
    border-radius: 15px;
    margin: 0 auto 110px;
}

.title {
    @include h2();
    font-family: 'Blazma', sans-serif;
    text-align: center;
    color: white;
    margin-bottom: 40px;
}

.subtitle {
    @include h3();
    text-transform: initial;
    text-align: center;
    white-space: pre-wrap;
    color: white;
    margin-bottom: 30px;
}

.button {
    margin: 0 auto;
}

@media (max-width: 1200px) {
    .join {
        max-width: calc(100vw - 40px);
        margin-bottom: 50px;
    }
}
@media (max-width: 480px) {
    .join {
        max-width: calc(100vw - 40px);
        padding: 25px;
    }
}
