import React from 'react'
import s from './Contacts.module.scss'
import {getActualYear} from '../../utils/utils'
import ContactsJoin from './ContactsJoin/ContactsJoin'
import ContactItem from './ContactItem/ContactItem'
import cl from 'classnames'

const Contacts = () => {
    const year = getActualYear()

    return (
        <>
            <section id={'contacts'} className={s.container}>
                <ContactsJoin />
                <div className={s.contacts}>
                    <ContactItem name={'Сайт'} className={s.left}>
                        <a href={'https://alliance-sport-agency.ru'} target={'_blank'} rel={'noopener noreferrer'} className={cl(s.value, s.left)}>
                            alliance-sport-agency.ru
                        </a>
                    </ContactItem>
                    <ContactItem name={'Телефон / WhatsApp'}>
                        <a href={'tel:+7-(919)-107-00-49'} className={s.value}>
                            +7 (919) 107 00-49
                        </a>
                    </ContactItem>
                    <ContactItem name={'Email'} value={'alliance-sport-agency@gmail.com'} >
                        <a href={'mailto:alliance-sport-agency@gmail.com'} className={s.value}>
                            alliance-sport-agency@gmail.com
                        </a>
                    </ContactItem>
                    <ContactItem name={'Telegram'} value={'@menshenin_alexey'} target={'_blank'} rel={'noopener noreferrer'} className={s.right}>
                        <a href={'https://t.me/menshenin_alexey'} className={cl(s.value, s.right)}>
                            @menshenin_alexey
                        </a>
                    </ContactItem>
                </div>
            </section>
            <footer className={s.footer}>
                All rights reserved {year}. Alliance Sport Agency by Menshenin.
            </footer>
        </>
    )
}

export default Contacts
