@import './src/_typography.scss';

.stat {
    display: grid;
    grid-template-columns: 1fr 1px 1fr 1px 1fr 1px 1fr;
    margin-bottom: 40px;
}

.itemTitle {
    font-weight: 400;
    font-size: 40px;
    line-height: 46px;
    color: $blue;
    text-align: center;
    font-family: 'Blazma', sans-serif;
    margin-bottom: 10px;
}

@media (max-width: 1200px) {
    .stat {
        margin-bottom: 25px;
    }
}

@media (max-width: 650px) {
    .stat {
        grid-template-columns: 1fr 1px 1fr;
    }
}
@media (max-width: 300px) {
    .stat {
        grid-template-columns: 1fr;
    }
}
