@import './src/_typography.scss';

.header {
    width: 100%;
    top: 0;
    background: $dark;
    position: sticky;
    z-index: 1000;
    padding: 23px 0;
    border-bottom: 1px solid rgba(255,255,255, 0.05);
}

.container {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-column-gap: 20px;
    justify-content: space-between;
    align-items: center;

    max-width: 1200px;
    margin: 0 auto;
}

.logo {

    & img {
        display: block;
    }
}

.button {
    width: fit-content;

    & img {
        display: block;
    }
}

@media (max-width: 1200px) {
    .container {
        padding: 0 20px;
    }
}

@media (max-width: 992px) {
    .container {
        display: flex;
        justify-content: space-between;
    }
    .requestButtonContainer {
        padding: 0 20px;
    }
    .requestButton {
        margin: 0 auto;
    }
}

@media (max-width: 480px) {
    .logo {

        & img {
            max-width: 200px;
        }
    }
}
@media (max-width: 320px) {
    .logo {

        & img {
            max-width: 150px;
        }
    }

    .button {
        & img {
            width: 24px;
        }
    }
}
