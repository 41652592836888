.preloaderContainer {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;

    & img {
        display: block;
        width: 17px;
        height: 17px;
    }
}

