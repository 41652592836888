import {getClasses} from '../../utils/getClasses'
import {useMemo} from 'react'
import styles from './Button.module.scss'

export const useButton = (props) => {
    const classes = useMemo(() => {
        const conditions = {
            "button": true,
            "button-red": props.color === 'red',
        };
        return getClasses(conditions, styles, props.className)
    }, [props]);

    return {classes}
}
