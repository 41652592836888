@import './src/_typography.scss';

.modalWrapper {
    position: fixed;
    inset: 0;
    z-index: 10001;
    width: 100vw;
    min-height: 100vh;
    display: grid;
    padding-top: 5vh;

    & .modalShadow {
        backdrop-filter: blur(5px);
        position: absolute;
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
        z-index: 10000;
        background: rgba(0, 0, 0, 0.6);
    }

    & .modal {
        position: relative;
        z-index: 10001;
        height: 90vh;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        background-color: white;
        padding: 50px 15px 50px 60px;
        border-radius: 15px;
        box-shadow: 0px -4px 80px rgba(0, 0, 0, 0.15);
        animation: display 0.2s linear;

        & .inner {
            overflow-y: scroll;
            height: 100%;
            padding-right: 35px;

            &::-webkit-scrollbar {
                width: 5px;
            }
            /* Track */
            &::-webkit-scrollbar-track {
                border-radius: 10px;
            }
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: rgba(30, 30, 30, 0.1);
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: rgba(30, 30, 30, 0.3);
            }
            &::-webkit-scrollbar-thumb:active {
                background: rgba(30, 30, 30, 0.3);
            }

            & .cross {
                cursor: pointer;
                width: 24px;
                height: 24px;
                display: block;
                position: absolute;
                top: 30px;
                right: 30px;
            }
        }

    }
}

@keyframes display {
    from {
        transform: translateY(200px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width: 650px) {
    .modalWrapper {
        padding-top: 12vh;

        & .modal {
            height: 65vh;
            padding: 30px 15px 30px 30px;

            & .inner {
                padding-right: 15px;

                & .cross {
                    top: 12px;
                    right: 12px;
                }
            }
        }
    }
}
