@import './src/_typography.scss';

.background {
    pointer-events: none;
    user-select: none;
    @include absoluteCenter();
    top: initial;
    z-index: 1;
    bottom: 50px;
    width: 100%;
    display: grid;
    align-items: center;

    & img {
        width: 100%;
        display: block;
        margin: 0 auto;
    }
}
